import React, { useRef,useState } from 'react';
import hero from "../utils/images/hero.png";
import contact from "../utils/images/contact.png";
import vedant from "../utils/images/vedant.png";
import harshit from "../utils/images/harshit.png";
import about from "../utils/images/about.png";
import Icons from "../utils/icon";
import toast from "react-hot-toast";
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';


const JobHubHome = () => {
  
  //temporary search functionality until the api route is made
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    const trimmedQuery = searchQuery.trim();
    if (trimmedQuery) {
      navigate(`/jobs?q=${encodeURIComponent(trimmedQuery)}`);
    } else {
       toast("Enter something to search",{icon: '🙃'});
    }
  };

  ///////////////////////////////////////////////////////////

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const SERVICE_ID ='service_ag7665r';
    const TEMPLATE_ID = 'template_wmj48za';
    const PUBLIC_KEY = 'yflumlbrmTIZugywU'
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY).then(
      (result) => {
        toast.success("Feedback Sent !");
      },
      (error) => {
        toast.error("Failed to send feedback !");
      }
    );
  };

  return (
    <div className='font-out'>
      {/* top section */}
      <div
        className="md:mt-16 flex bg-sky-50 items-center py-10 px-44 xl:px-32 lg:px-24 md:px-20 md:flex-col sm:p-5 sm:py-10"
        id="home"
      >
        <div className="w-1/2 md:w-full">
          <h1 className="2xl:text-6xl xl:text-6xl lg:text-[3rem] lg2:text-[2.3rem]">
          Seek Out the Finest Job Opportunities Here
          </h1>
          <p className="text-gray-500 lg2:text-[0.9rem]">
            Finding jobs is not easy, but we are here to help you so be ready to
            Discover Exciting Opportunities and Transform Your Future.
          </p>
          <div className="flex w-full mt-10 md:mt-4">
            <div className="flex items-center bg-white rounded-l-md border-1 border-blue-500 p-2 w-75 md:text-[0.8rem]">
              {Icons.search}
              <input placeholder="Search" className="ml-2 w-full outline-0" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
            </div>
            <button className="bg-blue-500 rounded-r-md text-sm font-semibold text-white p-1 w-25 md:text-[0.8rem] transition duration-400 hover:bg-blue-700" onClick={handleSearch}>
              Find a Job
            </button>
          </div>
        </div>
        
        <img src={hero} alt="home-img" className="w-1/2 md:w-80"/>
        
      </div>

      {/* about section */}
      <div id="about" className="flex gap-5 px-44 py-20 items-center justify-center xl:px-32 lg:px-24 lg2:flex-col md:p-20 sm:px-5">
        <img
          src={about}
          alt="about-img"
          className="w-1/2 lg2:order-2 lg2:w-96"
        />
        <div className="w-1/2 lg2:order-1 lg2:w-full">
          <h1 className="text-5xl font-bold mb-4 xl:text-4xl lg2:text-[2rem] lg2:text-center">
            About Us
          </h1>
          <p className="text-gray-500 font-semibold text-justify sm:text-[0.9rem]">
            Hey there, job seekers and dream chasers! Welcome to <b>DevJobs</b>,
            the cool corner of the internet where careers get a makeover! 🚀
            We're not your average job portal, think of us as your career
            wingman, guiding you through the professional jungle. No
            personalized job-hunting adventures or company gossip here – just
            straightforward access to the most exciting career possibilities.
            Welcome to a no-nonsense approach to finding your next big career
            move. It's all about the jobs at <b>DevJobs</b> – simple, direct,
            and ready for exploration!🌐💼 <span><a href='https://www.linkedin.com/company/99209148/'>Linkedin</a></span>
          </p>
        </div>
      </div>

      {/* extension section */}
      <div
        className="flex justify-between px-44 py-20 bg-[#212e7c] gap-5 text-white xl:px-32 lg:px-24 lg2:p-20 md:flex-col sm:px-5"
        id="extension"
      >
        <div>
          <h1 className="text-5xl lg2:text-4xl sm:text-3xl">
            Free extension for your browser
          </h1>
          <p className="my-3 sm:text-sm">
            JobsPool will help you notify about all latest jobs.
          </p>
          <div
            className="flex gap-10 my-4"
            style={{ fontSize: "24px", color: "gray" }}
          >
            <div style={{ color: "white" }}>{Icons.chrome}</div>
            {Icons.edge}
            {Icons.safari}
            {Icons.opera}
            {Icons.firefox}
          </div>
          <button
            className="flex text-md items-center bg-blue-500 rounded-md px-5 py-2 gap-3 mt-5 transition-transform duration-400 hover:scale-105 hover:bg-black sm:text-sm"
            onClick={() => 
            toast.success(`Feature in development – will be live soon!`)
          
            }
          >
            <div style={{ fontSize: "20px" }}>{Icons.chrome}</div>Install
          </button>
        </div>
        <div>
          <h1 className="text-3xl mb-[40px] lg2:text-2xl">
            Benifits of JobsPool for chrome:
          </h1>
          <div className="flex flex-col gap-3 text-sm">
            <span className="flex items-center gap-3">
              <div style={{ fontSize: "24px" }}>{Icons.check}</div>Real-time
              Opportunity Alerts
            </span>
            <span className="flex items-center gap-3">
              <div style={{ fontSize: "24px" }}>{Icons.shield}</div>Seamless
              Browsing Integration
            </span>
            <span className="flex items-center gap-3">
              <div style={{ fontSize: "24px" }}>{Icons.time}</div>First to Know
              Advantage
            </span>
            <span className="flex items-center gap-3">
              <div style={{ fontSize: "24px" }}>{Icons.eye}</div>Always
              Up-to-Date Opportunities
            </span>
          </div>
        </div>
      </div>

      {/* team section */}
      <div id="team" className="text-center mx-auto max-w-5xl px-2.5 py-20 items-center sm:pt-10 md:max-w-xl">
        <h1 className="text-4xl mb-3 font-bold sm:text-3xl">Meet our team</h1>
        <p className="text-md text-gray-500 mb-5 sm:text-[0.9rem] ">
          Meet the powerhouse behind our accomplishments – a team of exceptional
          individuals
        </p>
        <div className="flex gap-10 justify-center flex-wrap sm:gap-5">
          <div className="rounded-md shadow-md w-56 sm:w-36 transition-transform duration-400 hover:scale-105">
            <img
              src={vedant}
              alt="vedant"
              className="rounded-md aspect-[4/5]"
            />
            <div className="text-center p-2">
              <h4 className="text-xl font-bold mb-0 sm:text-sm">
                Vedant Sharma
              </h4>
              <p className="text-gray-500 text-sm font-normal sm:text-[0.6rem]">
                Founder & Developer
              </p>
              <div className="flex justify-center gap-4 cursor-pointer mb-2">
                <a href='https://www.linkedin.com/in/vedantsharma13/' className='underline-none text-inherit'>{Icons.linkedin}</a>
                <a href="mailto:vedants2003@gmail.com" className='underline-none text-inherit'>{Icons.mail}</a>
              </div>
            </div>
          </div>
          <div className="rounded-md shadow-md w-56 sm:w-36 transition-transform duration-400 hover:scale-105">
            <img
              src={harshit}
              alt="vedant"
              className="rounded-md aspect-[4/5]"
            />
            <div className="text-center p-2">
              <h4 className="text-xl font-bold mb-0 sm:text-sm">
                Harshit Maurya
              </h4>
              <p className="text-gray-500 text-sm font-normal  sm:text-[0.6rem]">
                Founder & Developer
              </p>
              <div className="flex justify-center gap-4 cursor-pointer mb-2">
              <a href='https://www.linkedin.com/in/harshitmaurya/' className='underline-none text-inherit'>{Icons.linkedin}</a>
              <a href="mailto:harshitmaurya.cse@gmail.com" className='underline-none text-inherit'>{Icons.mail}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* contact section */}
      <div id="contact" className="flex px-44 py-16 items-center bg-sky-50 gap-5 xl:px-32 lg:px-24 lg2:px-20 md:flex-col sm:px-5">
        <img
          src={contact}
          alt="form-img"
          style={{ transform: "scaleX(-1)" }}
          className="w-50 md:hidden"
        />
        <div>
          <h1 className="text-5xl font-bold lg2:text-4xl sm:text-3xl">FEEDBACK</h1>
          <p className="text-slate-600 sm:text-[0.9rem]">
            Your thoughts matter! Share your experience and help us grow – leave
            a feedback to make your voice heard and our platform even better!
          </p>
          <form ref={form} onSubmit={sendEmail} className="flex flex-col gap-4">
            <input
              name='name'
              placeholder="Your Name"
              className="p-2 bg-slate-200 rounded-md outline-0 sm:text-sm"
              required
            />
            <input
              name='email'
              type='email'
              placeholder="Your Mail"
              className="p-2 bg-slate-200 rounded-md outline-0 sm:text-sm"
              required
            />
            <textarea
              name="project"
              placeholder="Share your thoughts"
              className="p-2 bg-slate-200 h-36 rounded-md outline-0 sm:text-sm"
              required
            />
            <button
              type="submit"
              className=" flex gap-2 items-center justify-center bg-blue-500 rounded-md py-2 text-white font-semibold tracking-widest sm:text-[0.8rem]"
            >
              <div style={{ fontSize: "20px" }}>{Icons.send}</div>SEND
            </button>
          </form>
        </div>
      </div>

    </div>
  );
};

export default JobHubHome;
