import React, { useEffect, useState } from "react";
import Comment from '../components/Comment';
import Loader from "../components/Loader";
import axios from "axios";
import toast from "react-hot-toast";
import { useCookies } from 'react-cookie';


const Forum = ({isLoggedIn}) => {
    const [cookies] = useCookies(['_user']);
    const userData= cookies['_user'];
    const [jobComments, setjobComments] = useState([]);
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("No Discussion started Yet!");

    const handlePostComment= async(e)=>{
        e.preventDefault();
        try{
          if(!isLoggedIn){
             toast.error("Login to post a comment");
             setComment("");
             return;
          } 
          const data={
           commentData:comment,
           commentedBy:userData._id,
           postId:'67070704821c192aa77b1c9c',
          }
           const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/addComment`,data);
           const res=response.data;
           setjobComments(prevComments => [...prevComments, res.comment]);
           setComment("");
           toast.success("comment posted");
        }catch(e){
            toast.error("An error occurred while posting comment");
        }
     }

     useEffect(() => {
        const fetchComments = async () => {
            try {
              const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getComments?jobId=67070704821c192aa77b1c9c`);
              if(res.data.status) setjobComments(res.data.comments);
            } catch (e) {
               if(e.response && e.response.status===500){
                toast.error("Error while fetching comments");
                setError("Error while fetching comments");
               }
            }finally{
              setLoading(false);
            }
        };
    
        fetchComments();
      }, []);

  return (
    <div className="my-24 px-20 md:px-2 mb-4 ">
    <h2 className='text-2xl sm:text-xl'>DevJobs Forum</h2>
    <form className='flex gap-2 sm:flex-col' onSubmit={handlePostComment}>
      <input
        type='text'
        placeholder='Join the discussion!'
        className='rounded-md p-2 w-full border-2 border-gray-200 focus:border-blue-300 outline-0 sm:text-sm'
        value={comment}
        onChange={(e)=>setComment(e.target.value)}
        required
      />
      <button type='submit' className='bg-blue-500 rounded-md px-4 py-2 font-semibold text-white hover:bg-blue-700 sm:text-sm shadow-md' >Comment</button>
    </form>
    {/* filter options */}
    <div className='my-3'>
      {/* <select className='bg-slate-700 p-2 w-56 sm:w-36 rounded-md sm:text-sm outline-0 text-white'>
        <option value="">Filter</option>
        <option value="Top">Top</option>
        <option value="Newest">Newest</option>
        <option value="Oldest">Oldest</option>
      </select> */}
    </div>
    {/* comment section */}
    <div>
    {loading ? (
        <div className='flex items-center justify-center bg-slate-200 rounded-md h-72'><Loader /></div>
      ) : jobComments.length === 0 ? (
        <div className='flex items-center justify-center bg-slate-200 font-semibold rounded-md h-72'>{error}</div>
      ) : (
        <div>
          {jobComments.map(comment => (
            <Comment key={comment._id} comment={comment} isLoggedIn={isLoggedIn} />
          ))}
        </div>
      )}
    </div>
</div>
  )
}

export default Forum